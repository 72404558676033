import React, { useContext, useEffect } from 'react'
import style from './layout.mod.scss'
import PropTypes from 'prop-types'
import { JsonLd } from 'react-schemaorg'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { useStaticQuery, graphql } from 'gatsby'
import Headroom from 'react-headroom'
import { Header, Footer, Drilldown, Search, SearchModal } from '@components'
import ThemeContext from '@context/themeContext'
import SearchContext from '@context/searchContext'

const Layout = ({ children, pageType = '', isWecareNowPage }) => {
  const { site, allSitePage } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          lang
          siteUrl
        }
      }

      allSitePage {
        nodes {
          ...MenuHelpersAllPages
        }
      }
    }
  `)

  const { isSidebarOpen, closeSidebar } = useContext(ThemeContext)
  const { isSearchModalOpen, setIsSearchModalOpen } = useContext(SearchContext)

  useEffect(() => {
    closeSidebar()
  }, [closeSidebar])

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: site.siteMetadata.title,
    description: site.siteMetadata.description,
    email: site.siteMetadata.email,
    contactPoint: [
      {
        '@type': 'ContactPoint',
        email: site.siteMetadata.email,
        contactType: 'customer service'
      }
    ],
    url: site.siteMetadata.siteUrl,
    logo: site.siteMetadata.logo,
    sameAs: [site.siteMetadata.facebook, site.siteMetadata.linkedin]
  }

  const menuTreeFromPages = nodes => {
    const allMenuItems = nodes
      .filter(node => node.pageContext !== null && node.pageContext.showPage)
      .map(node => {
        return { path: node.path, ...node.pageContext }
      })

    const datoPages = allMenuItems
      .filter(item => item.hasParent === false && item.type === 'DatoCmsPage')
      .sort((a, b) => a.position - b.position)

    datoPages.forEach(parent => {
      const { treeChildren } = parent
      if (treeChildren.length > 0) {
        parent.treeChildren = treeChildren
          .filter(child => child.showPage)
          .map(child => {
            return {
              ...child
            }
          })
          .sort((a, b) => a.position - b.position)
      }
    })
    return [...datoPages]
  }

  const wecareNowMenuTreeFromPages = nodes => {
    const menuitems =
      nodes.filter(node => node.path === '/wecare-now/')[0].pageContext
        .treeChildren || []

    const datoPages = menuitems
      .filter(
        item =>
          item?.treeParent !== null &&
          item?.internal?.type === 'DatoCmsPage' &&
          item.showPage
      )
      .sort((a, b) => a.position - b.position)

    datoPages.forEach(parent => {
      const { treeChildren } = parent
      if (treeChildren.length > 0) {
        parent.treeChildren = treeChildren
          .filter(child => child.showPage)
          .map(child => {
            return {
              ...child
            }
          })
          .sort((a, b) => a.position - b.position)
      }
    })

    return [...datoPages]
  }

  const menuItems = menuTreeFromPages(allSitePage.nodes)
  const wecareMenuItems = false

  return (
    <>
      <HelmetDatoCms defer={false}>
        <meta name="format-detection" content="telephone=no" />
        <html lang="en" />
      </HelmetDatoCms>
      <div id="layout" className={style.layout}>
        <Headroom disableInlineStyles>
          <Header
            menuItems={menuItems}
            wecareMenuItems={wecareMenuItems}
            isWecareNowPage={false}
          />
        </Headroom>
        <SearchModal
          setIsModalOpen={setIsSearchModalOpen}
          isModalOpen={isSearchModalOpen}
        >
          <Search />
        </SearchModal>

        <div className={style.layout__content}>
          <main>{children}</main>
        </div>
        <Footer />
        {isSidebarOpen && (
          <div className={style.layout__sidebar}>
            <Drilldown
              isVisible={isSidebarOpen}
              items={menuItems}
              closeSidebar={closeSidebar}
            />
          </div>
        )}
        <JsonLd item={schema} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
