import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import style from './feature-item.mod.scss'
import { Button, Content } from '@components'

const FeatureItem = ({ button, heading, content, image, theme }) => {
  const themeScheme = theme?.length ? theme[0].scheme : false

  return (
    <div className={style[`feature-item`]}>
      <div className={style[`feature-item__image`]}>
        {image.format === 'svg' ? (
          <img className={style[`feature-item__image-svg`]} src={image.url} />
        ) : (
          <GatsbyImage image={image.gatsbyImageData} alt={image.alt || ''} />
        )}
      </div>
      <div className={style[`feature-item__content`]}>
        <div className={themeScheme ? style[`feature-item_theme-exists`] : ''}>
          <h5 className={themeScheme ? style[`theme-${themeScheme}`] : ''}>
            {heading}
          </h5>
        </div>
        <Content content={content} />
        {(button?.link?.path || button?.url) && (
          <div className={style[`feature-item__button`]}>
            <Button
              to={button?.link?.path || button.url}
              children={button?.displayText}
              length={'large'}
              size={'large'}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default FeatureItem

export const query = graphql`
  fragment FeatureItem on DatoCmsFeatureItem {
    button {
      ...Link
    }
    heading
    content
    image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { h: "183", fit: "crop", w: "183", q: 100 }
      )
      format
      url
      alt
    }
    id
    model {
      apiKey
    }
    theme {
      id
      scheme
    }
    paddingBottom
    paddingTop
    __typename
  }
`
