import React, { useState } from 'react'
import { graphql } from 'gatsby'
import style from './accordion.mod.scss'
import { motion } from 'framer-motion'
import { Content, Button } from '@components'
import { FaChevronDown } from 'react-icons/fa'

const Accordion = ({
  items,
  heading,
  content,
  textAlign,
  button,
  headingColour,
  theme
}) => {
  const [expanded, setExpanded] = useState(0)
  const classes = [style.accordion]
  if (theme) {
    classes.push(`${style.accordion}--theme-${theme}`)
  }
  textAlign === 'Centre' && classes.push(`${style.accordion}--center`)

  return (
    <div className={[...classes].join(' ')}>
      {heading && (
        <div className={style[`accordion--heading-color-${headingColour}`]}>
          <h5>{heading}</h5>
        </div>
      )}
      {content && (
        <div
          className={style.accordion__body}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
      {items.map(({ title, description }, i) => {
        const isOpen = i === expanded
        return (
          <div className={style.accordion__item} key={`accordion-item-${i}`}>
            <a
              href={`#accordion-item-${i}`}
              onClick={e => {
                e.preventDefault()
                setExpanded(isOpen ? false : i)
              }}
              className={style.accordion__header}
            >
              <div
                className={style[`accordion--heading-color-${headingColour}`]}
              >
                <h6>{title}</h6>
              </div>
              <span className={style.accordion__arrow}>
                <motion.span
                  className={style.accordion__icon}
                  animate={isOpen ? 'open' : 'collapsed'}
                  initial={'collapsed'}
                  variants={{
                    open: { rotate: 180 },
                    collapsed: { rotate: 0 }
                  }}
                >
                  <FaChevronDown />
                </motion.span>
              </span>
            </a>

            <motion.div
              layoutTransition
              useInvertedScale
              key={`accordion-item-motion-${i}`}
              id={`accordion-item-${i}`}
              animate={isOpen ? 'open' : 'collapsed'}
              initial={'collapsed'}
              variants={{
                open: { height: '100%', opacity: 1 },
                collapsed: {
                  height: '0',
                  opacity: 0,
                  transition: {
                    opacity: {
                      duration: 0.2
                    }
                  }
                }
              }}
            >
              {description && (
                <div className={style.accordion__content}>
                  <Content content={description} />
                </div>
              )}
            </motion.div>
          </div>
        )
      })}
      {(button?.link?.path || button?.url) && (
        <div className={style.accordion__button}>
          <Button
            size="large"
            alignment="center"
            buttonStyle={'secondary'}
            to={button?.link?.path || button?.url}
          >
            {button.displayText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Accordion

export const query = graphql`
  fragment Accordion on DatoCmsAccordion {
    id
    model {
      apiKey
    }
    paddingTop
    paddingBottom
    __typename
    heading
    headingColour
    button {
      ...Link
    }
    content
    textAlign
    items {
      title
      description
    }
  }
`
