import React from 'react'
import style from './pricing.mod.scss'
import { Content, Button } from '@components'
import { graphql } from 'gatsby'

const Pricing = ({
  heading,
  leftHeading,
  leftButton,
  leftText,
  registeredText,
  unregisteredText
}) => {
  return (
    <div className={style.pricing}>
      <h2>{heading}</h2>
      <div className={style.pricing__content}>
        <div className={style.pricing__left}>
          <h5>{leftHeading}</h5>
          <p>{leftText}</p>
          {(leftButton?.link?.path || leftButton?.url) && (
            <div className={style.pricing__button}>
              <Button
                buttonStyle={'secondary'}
                to={leftButton?.link?.path || leftButton.url}
                children={leftButton.displayText}
              />
            </div>
          )}
        </div>
        <div className={style.pricing__right}>
          <div className={style.pricing__registered}>
            <p className={style.pricing__subheading}>Enrolled</p>
            <Content content={registeredText} textAlign="left" />
          </div>
          <div className={style.pricing__unregistered}>
            <p className={style.pricing__subheading}>Casual</p>
            <Content content={unregisteredText} textAlign="left" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing

export const query = graphql`
  fragment Pricing on DatoCmsPricing {
    heading
    leftHeading
    leftButton {
      ...Link
    }
    leftText
    paddingBottom
    paddingTop
    registeredText
    unregisteredText
    __typename
  }
`
