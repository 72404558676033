import React, { useState, useRef } from 'react'
import style from './options-for-care.mod.scss'

const OptionsForCare = ({ data, onBackToTriage }) => {
  const [expanded, setExpanded] = useState({})

  const linkRefs = useRef({})

  const getLinkRef = optionId => {
    if (!linkRefs.current[optionId]) {
      linkRefs.current[optionId] = React.createRef()
    }
    return linkRefs.current[optionId]
  }

  if (!data) return null

  const handleLinkClick = link => {
    if (link.url) {
      window.open(link.url, '_blank')
    } else if (link.link?.path) {
      window.location.href = link.link.path
    }
  }

  const toggleExpand = optionId => {
    setExpanded(prev => ({
      ...prev,
      [optionId]: !prev[optionId]
    }))
  }

  return (
    <div className={style['options-for-care']}>
      <div className={style['options-for-care__container']}>
        <h2 className={style['options-for-care__title']}>
          Here are your options for care
        </h2>

        <div className={style['options-for-care__grid']}>
          {data.options.map(option => {
            console.log(option)
            const isExpanded = expanded[option.id] || false

            const alwaysVisibleLinks = option.links?.slice(0, 3) || []
            const extraLinks = option.links?.slice(3) || []

            return (
              <div key={option.id} className={style['options-for-care__item']}>
                <div
                  className={`${style['options-for-care__item__header']} theme-${option.colorScheme[0].scheme}`}
                >
                  <h3
                    className={style['options-for-care__item__header__title']}
                  >
                    {option.title}
                  </h3>
                </div>

                <div className={style['options-for-care__item__content']}>
                  {alwaysVisibleLinks.length > 0 && (
                    <div
                      className={
                        style['options-for-care__item__content__links']
                      }
                    >
                      {alwaysVisibleLinks.map(l => (
                        <div
                          key={l.id}
                          className={
                            style['options-for-care__item__content__links__row']
                          }
                        >
                          <button
                            onClick={() => handleLinkClick(l)}
                            className={
                              style[
                                'options-for-care__item__content__links__row__button'
                              ]
                            }
                          >
                            {l.displayText}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}

                  {extraLinks.length > 0 && (
                    <div
                      ref={getLinkRef(option.id)}
                      className={
                        style['options-for-care__item__content__collapsible']
                      }
                      style={{
                        maxHeight: isExpanded
                          ? linkRefs.current[option.id]?.current?.scrollHeight
                            ? linkRefs.current[option.id].current.scrollHeight +
                              'px'
                            : 'none'
                          : '0px'
                      }}
                    >
                      {extraLinks.map(l => (
                        <div
                          key={l.id}
                          className={
                            style['options-for-care__item__content__links__row']
                          }
                        >
                          <button
                            onClick={() => handleLinkClick(l)}
                            className={
                              style[
                                'options-for-care__item__content__links__row__button'
                              ]
                            }
                          >
                            {l.displayText}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}

                  {extraLinks.length > 0 && (
                    <div
                      className={
                        style['options-for-care__item__content__toggle']
                      }
                      onClick={() => toggleExpand(option.id)}
                    >
                      <span>{isExpanded ? 'Show less' : 'View all'}</span>
                      <span
                        className={`${
                          style[
                            'options-for-care__item__content__toggle__caret'
                          ]
                        } ${
                          isExpanded
                            ? style[
                                'options-for-care__item__content__toggle--up'
                              ]
                            : ''
                        }`}
                      >
                        <svg
                          width="12"
                          height="7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.783 5.88.888 1.48a.505.505 0 0 1 0-.769l.57-.513a.654.654 0 0 1 .854 0L6.21 3.683 10.108.197a.654.654 0 0 1 .854.001l.571.513a.505.505 0 0 1 0 .769l-4.895 4.4a.654.654 0 0 1-.855 0Z"
                            fill="#000"
                            fillOpacity=".5"
                          />
                        </svg>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <button
        className={style['options-for-care__back-btn']}
        onClick={onBackToTriage}
      >
        Back to start
      </button>
    </div>
  )
}

export default OptionsForCare
