import React from 'react'
import style from './ticker.mod.scss'
import { Button } from '@components'

// import Swiper core and required modules
import { Navigation, A11y, Autoplay } from 'swiper/modules'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

const Ticker = ({ items }) => {
  return (
    <div className={style.ticker}>
      <div className={style.ticker__wrap}>
        <Swiper
          // install Swiper modules
          modules={[Navigation, A11y, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          //loop={true}
          autoplay={{ delay: 3000, stopOnLastSlide: true }}
          onSwiper={swiper => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className={`${style.ticker__item} ${
                  items.length === 1 ? style.ticker__item_single : ''
                }`}
              >
                {' '}
                <p>
                  {item.text}
                  {item.link && (
                    <span className={style.ticker__dash}>{'-'}</span>
                  )}
                </p>
                {(item.link?.link?.path || item.link?.url) && (
                  <Button unstyled to={item.link?.link?.path || item.link?.url}>
                    {item.link.displayText}
                  </Button>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default Ticker
