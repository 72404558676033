import React from 'react'
import style from './menu-item.mod.scss'
import { Button } from '@components'
import Arrow from '@svgs/arrow-right.svg'
import { motion } from 'framer-motion'

const MenuItem = ({
  footer = false,
  modifiers = [],
  displayText,
  link,
  url,
  disableMenuClick,
  showIcon = false,
  backIcon = false,
  vertical = false,
  tabIndex = 0,
  isButton = false,
  onClick,
  onClickArrow,
  unstyled = false,
  isMobile = false
}) => {
  footer && modifiers.push(style['menu-item--footer'])
  vertical
    ? modifiers.push(style['menu-item--vertical'])
    : modifiers.push(style['menu-item--horizontal'])
  showIcon && modifiers.push(style['menu-item--has-icon'])
  backIcon && modifiers.push(style['menu-item--is-back'])
  isMobile && modifiers.push(style['menu-item--is-mobile'])

  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 16,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  }

  return (
    <motion.li
      className={[style['menu-item'], ...modifiers].join(' ')}
      variants={variants}
    >
      <div className={style['menu-item__wrap']}>
        <Button
          onClick={disableMenuClick ? onClickArrow : onClick}
          tabIndex={tabIndex}
          isMenuItem
          to={link && link.path ? link.path : url}
          unstyled={isButton && !unstyled ? false : true}
          activeClass="menu-item--current"
        >
          {backIcon && <Arrow className={style['menu-item__back']} />}
          <span>{displayText}</span>
        </Button>
        {showIcon && (
          <Button
            onClick={onClickArrow}
            tabIndex={tabIndex}
            type={'button'}
            isMenuItem
            to={link && link.path ? link.path : url}
            unstyled={isButton && !unstyled ? false : true}
          >
            <Arrow className={style['menu-item__icon']} />
          </Button>
        )}
      </div>
    </motion.li>
  )
}

export default MenuItem
