import React from 'react'
import { graphql, Link } from 'gatsby'
import style from './checklist-grid.mod.scss'
import { Button } from '@components'

const ChecklistGrid = ({
  checklistHeadingPrimary,
  itemsPrimary,
  checklistHeadingSecondary,
  itemsSecondary,
  link,
  icon,
  theme
}) => {
  // Add theme color mapping
  const themeColors = {
    careNow: { backgroundColor: '#00a098', path: '#fff', button: 'green' },
    dropInCare: { backgroundColor: '#f26862', path: '#000', button: 'red' },
    medDirect: { backgroundColor: '#ffe512', path: '#000', button: 'yellow' },
    generalPractice: {
      backgroundColor: '#5cb7e7',
      path: '#000',
      button: 'blue'
    }
  }

  // Get colors based on theme
  const colors = theme
    ? themeColors[theme]
    : { backgroundColor: '#00a098', path: '#000', button: 'secondary' }

  return (
    <div className={style['checklist-grid']}>
      <div className={style['checklist-grid__wrapper']}>
        {/* Left-hand image or illustration */}
        <div
          className={style['checklist-grid__image-wrapper']}
          style={{ backgroundColor: `${colors.backgroundColor}40` }}
        >
          <div className={style['checklist-grid__image']}>
            {icon && icon.url && (
              <img
                src={icon.url}
                alt="Medical illustration"
                className={style['checklist-grid__img']}
              />
            )}
          </div>
        </div>

        {/* Right-hand content: headings + lists */}
        <div className={style['checklist-grid__lists']}>
          <div className={style['checklist-grid__list-group']}>
            <h2 className={style['checklist-grid__heading']}>
              {checklistHeadingPrimary}
            </h2>
            <ul className={style['checklist-grid__list']}>
              {itemsPrimary?.map(item => (
                <li
                  key={item.title}
                  className={style['checklist-grid__list-item']}
                >
                  <CheckIcon
                    className={style['checklist-grid__list-icon']}
                    circleFill={colors.backgroundColor}
                    pathFill={colors.path}
                  />
                  {item.link?.path ? (
                    <div className={style['checklist-grid__list-item__link']}>
                      <Link to={item.link.path}>{item.title}</Link>
                    </div>
                  ) : (
                    item.title
                  )}
                </li>
              ))}
            </ul>
          </div>

          {itemsSecondary && itemsSecondary.length > 0 && (
            <div className={style['checklist-grid__list-group']}>
              <h2 className={style['checklist-grid__heading']}>
                {checklistHeadingSecondary}
              </h2>
              <ul className={style['checklist-grid__list']}>
                {itemsSecondary.map(item => (
                  <li
                    key={item.title}
                    className={style['checklist-grid__list-item']}
                  >
                    <CheckIcon
                      className={style['checklist-grid__list-icon']}
                      circleFill={colors.backgroundColor}
                      pathFill={colors.path}
                    />
                    {item.link?.path ? (
                      <Link to={item.link.path}>{item.title}</Link>
                    ) : (
                      item.title
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {/* CTA button */}
          <div className={style['checklist-grid__button']}>
            <Button
              size="large"
              alignment="left"
              buttonStyle={colors.button}
              to={link?.link?.path || link?.url}
            >
              {link.displayText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChecklistGrid

// Add the CheckIcon component at the bottom of the file
const CheckIcon = ({
  className,
  circleFill = '#00a098',
  pathFill = '#000'
}) => (
  <svg
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
  >
    <circle cx="11" cy="11" r="11" fill={circleFill} fillOpacity=".4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.689 7.655c.277.24.303.657.058.93l-5.14 5.703a.67.67 0 0 1-.47.222.682.682 0 0 1-.49-.177l-3.351-3.07a.653.653 0 0 1-.03-.928.676.676 0 0 1 .944-.035l2.851 2.606 4.684-5.195a.67.67 0 0 1 .462-.22.682.682 0 0 1 .485.164h-.003Z"
      fill={pathFill}
    />
  </svg>
)

// The GraphQL fragment, unchanged:
export const query = graphql`
  fragment ChecklistGrid on DatoCmsChecklistGrid {
    itemsPrimary {
      title
      link {
        path
      }
    }
    itemsSecondary {
      title
      link {
        path
      }
    }
    checklistHeadingPrimary
    checklistHeadingSecondary
    link {
      ...Link
    }
    icon {
      url
    }
    paddingBottom
    paddingTop
    backgroundColour
    __typename
  }
`
