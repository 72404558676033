import React from 'react'
import { Block, TeamMemberCard, Grid } from '@components'

const DatoCmsTeamMemberCardGrid = ({
  paddingTop,
  paddingBottom,
  backgroundColour,
  teamMembers,
  linkToClinic,
  clinic,
  columns
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      backgroundColour={backgroundColour}
    >
      <Grid columns={columns} gridGap={columns === 3 ? 'slim' : 'large'}>
        {teamMembers.map(teamMember => {
          return (
            <TeamMemberCard
              {...teamMember}
              key={teamMember.id}
              linkToClinic={linkToClinic}
              teamClinic={clinic}
            />
          )
        })}
      </Grid>
    </Block>
  )
}

export default DatoCmsTeamMemberCardGrid
