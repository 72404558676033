import React from 'react'
import style from './grid.mod.scss'
import { graphql } from 'gatsby'

const Grid = ({
  children,
  columns = '2',
  gridGap,
  scrollMobile = false,
  offset = false,
  partition = false,
  centerLastItem = false
}) => {
  const modifiers = [style['grid--col-' + columns]]
  gridGap && modifiers.push(style[`grid--grid-gap-${gridGap}`])
  scrollMobile && modifiers.push(style[`grid--scroll-mobile`])
  offset && modifiers.push(style[`grid--offset`])
  partition && modifiers.push(style[`grid--partition`])
  centerLastItem && modifiers.push(style[`grid--center-last-item`])

  return (
    <div className={[style.grid, ...modifiers].join(' ')}>
      <div className={style.grid__wrapper}>{children}</div>
    </div>
  )
}

export default Grid

export const query = graphql`
  fragment CardGrid on DatoCmsCardGrid {
    paddingTop
    paddingBottom
    cards {
      ...Card
    }
    model {
      apiKey
    }
    __typename
  }

  fragment SimpleCardGrid on DatoCmsSimpleCardGrid {
    paddingTop
    paddingBottom
    backgroundColour
    subHeadingColour
    cards {
      ...SimpleCard
    }
    model {
      apiKey
    }
    __typename
  }

  fragment TeamMemberCardGrid on DatoCmsTeamMemberCardGrid {
    paddingTop
    paddingBottom
    backgroundColour
    columns
    teamMembers {
      ...TeamMemberCard
    }
    linkToClinic
    clinic {
      id
      name
      clinicLink {
        path
      }
    }
    model {
      apiKey
    }
    __typename
  }

  fragment PageCardGrid on DatoCmsPageCardGrid {
    paddingTop
    paddingBottom
    backgroundColour
    pages {
      ...PageCard
    }
    model {
      apiKey
    }
    __typename
  }

  fragment PathwayCardGrid on DatoCmsPathwayCardGrid {
    paddingTop
    paddingBottom
    cards {
      ...PathwayCard
    }
    model {
      apiKey
    }
    __typename
  }

  fragment ClinicCardGrid on DatoCmsClinicCardGrid {
    paddingTop
    paddingBottom
    clinics {
      ...ClinicCard
    }
    model {
      apiKey
    }
    __typename
  }
`
