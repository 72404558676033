import React from 'react'
import style from './breadcrumbs.mod.scss'
import { Link } from 'gatsby'

const Breadcrumbs = ({ breadcrumbs }) => {
  const modifiers = []

  return (
    <nav
      className={[style.breadcrumbs, ...modifiers].join(' ')}
      aria-label="Breadcrumb"
    >
      {breadcrumbs && (
        <ul className={style.breadcrumbs__list}>
          {breadcrumbs.map(
            ({ path, title, disableMenuClick }, i) => (
              console.log('disableMenuClick', disableMenuClick),
              (
                <li
                  key={`${i}-${title}`}
                  className={style.breadcrumbs__item}
                  aria-current={breadcrumbs.length - 1 === i ? 'page' : null}
                >
                  {disableMenuClick ? (
                    <a href="javascript:void(0)" children={title} />
                  ) : (
                    <Link to={path} children={title} />
                  )}
                </li>
              )
            )
          )}
        </ul>
      )}
    </nav>
  )
}

export default Breadcrumbs
