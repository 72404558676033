import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import style from './hero-with-triage-pathway.mod.scss'
import { motion, AnimatePresence } from 'framer-motion'

// Import the new component for "options for care"
import OptionsForCare from './options-for-care'

// Add these variants near the top of the component
const slideVariants = {
  enter: {
    x: '100%',
    opacity: 0,
    position: 'absolute',
    width: '100%'
  },
  enterFromLeft: {
    x: '-100%',
    opacity: 0,
    position: 'absolute',
    width: '100%'
  },
  center: {
    x: 0,
    opacity: 1,
    position: 'relative',
    width: '100%'
  }
}

const transition = {
  duration: 0.3,
  ease: 'easeInOut'
}

const HeroWithTriagePathway = () => {
  // Add ref for the component's container
  const containerRef = React.useRef(null)

  // Add scroll helper function
  const scrollToTop = () => {
    if (window.innerWidth <= 768) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  // 1) Fetch data
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsQuestion {
        nodes {
          id: originalId
          title
          isFirstQuestion
          answers {
            id: originalId
            title
            moduleArea {
              ... on DatoCmsTriageUrl {
                id
                url
                __typename
              }
              ... on DatoCmsTriageNextQuestion {
                id
                nextQuestion {
                  id: originalId
                }
                __typename
              }
              ... on DatoCmsTriageLink {
                id
                link {
                  ... on DatoCmsHome {
                    id
                    path
                  }
                  ... on DatoCmsPage {
                    id
                    path
                  }
                }
                __typename
              }
              ... on DatoCmsTriageOptionsForCare {
                id
                options {
                  id: originalId
                  title
                  colorScheme {
                    scheme
                  }
                  links {
                    id: originalId
                    link {
                      ... on DatoCmsHome {
                        id
                        path
                      }
                      ... on DatoCmsPage {
                        id
                        path
                      }
                    }
                    url
                    displayText
                  }
                }
                __typename
              }
            }
          }
        }
      }
    }
  `)

  const questions = data.allDatoCmsQuestion.nodes

  // 2) State for question-based triage
  const [currentQuestionId, setCurrentQuestionId] = useState(null)
  const [firstQuestionId, setFirstQuestionId] = useState(null)

  // 3) State for module-based flow
  const [activeModuleType, setActiveModuleType] = useState('question')
  // ^ Possible values: 'question', 'optionsForCare', etc.

  const [optionsForCareData, setOptionsForCareData] = useState(null)

  // Add this new state variable
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  // Update the useEffect to set isInitialLoad to false after setting the first question
  useEffect(() => {
    if (questions && questions.length > 0) {
      const firstQuestion =
        questions.find(q => q.isFirstQuestion) || questions[0]
      setFirstQuestionId(firstQuestion.id)
      setCurrentQuestionId(firstQuestion.id)
      // Set isInitialLoad to false after a brief delay
      setTimeout(() => setIsInitialLoad(false), 100)
    }
  }, [questions])

  // Helper: find question by ID
  const getQuestionById = id => questions.find(q => q.id === id)
  const currentQuestion = getQuestionById(currentQuestionId)

  // 4) Handle an answer click
  const onAnswerClick = answer => {
    const moduleData = answer.moduleArea?.[0]
    if (!moduleData) return

    setIsInitialLoad(false)
    // Add scroll behavior
    scrollToTop()

    switch (moduleData.__typename) {
      case 'DatoCmsTriageNextQuestion':
        if (moduleData.nextQuestion?.id) {
          setCurrentQuestionId(moduleData.nextQuestion.id)
        }
        break

      case 'DatoCmsTriageUrl':
        if (moduleData.url) {
          window.open(moduleData.url, '_blank')
        }
        break

      case 'DatoCmsTriageLink':
        if (moduleData.link?.path) {
          window.location.href = moduleData.link.path
        }
        break

      case 'DatoCmsTriageOptionsForCare':
        // Switch to "optionsForCare" module
        setActiveModuleType('optionsForCare')
        setOptionsForCareData(moduleData)
        break

      default:
        console.warn('Unrecognized module type:', moduleData.__typename)
        break
    }
  }

  // 5) Return statement with a switch on activeModuleType
  switch (activeModuleType) {
    // --- A) If we're showing the OptionsForCare module
    case 'optionsForCare':
      if (!optionsForCareData) return null
      return (
        <div style={{ backgroundColor: '#f1f1f1', overflow: 'hidden' }}>
          <AnimatePresence mode="wait">
            <motion.div
              key="options-for-care"
              initial="enter"
              animate="center"
              exit="exit"
              variants={slideVariants}
              transition={transition}
            >
              <OptionsForCare
                data={optionsForCareData}
                onBackToTriage={() => {
                  // Reset so we show triage flow again
                  setActiveModuleType('question')
                  setOptionsForCareData(null)
                  setCurrentQuestionId(firstQuestionId)
                  scrollToTop()
                }}
              />
            </motion.div>
          </AnimatePresence>
        </div>
      )

    // --- B) Otherwise, default to question-based triage
    default:
      // If there's no question loaded, bail
      if (!currentQuestion) return null

      return (
        <div
          ref={containerRef}
          style={{
            backgroundColor: '#00a7a0',
            overflow: 'hidden',
            position: 'relative'
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={currentQuestionId}
              initial={
                isInitialLoad
                  ? 'center'
                  : currentQuestionId === firstQuestionId
                  ? 'enterFromLeft'
                  : 'enter'
              }
              animate="center"
              exit="exit"
              variants={slideVariants}
              transition={transition}
              style={{
                position: 'absolute',
                width: '100%',
                top: 0,
                left: 0
              }}
            >
              <div className={style['pathway']}>
                <div className={style['pathway__container']}>
                  <h2 className={style['pathway__title']}>
                    {currentQuestion.title}
                  </h2>

                  <div className={style['pathway__answers']}>
                    {currentQuestion.answers.map(answer => (
                      <button
                        key={answer.id}
                        onClick={() => onAnswerClick(answer)}
                        className={style['pathway__answer-button']}
                      >
                        {answer.title}
                      </button>
                    ))}
                  </div>

                  {currentQuestionId !== firstQuestionId && (
                    <button
                      className={style['pathway__back-button']}
                      onClick={() => {
                        setCurrentQuestionId(firstQuestionId)
                        scrollToTop()
                      }}
                    >
                      Back to start
                    </button>
                  )}
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      )
  }
}

export default HeroWithTriagePathway
