import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import style from './testimonial.mod.scss'
import { Content } from '@components'

// Import Swiper core and required modules
import { Navigation, A11y, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

const Testimonial = ({ items }) => {
  const swiperRef = useRef(null)

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  return (
    <div className={style.testimonial}>
      <div className={style.testimonial__wrap}>
        <Swiper
          ref={swiperRef}
          modules={[A11y, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          //autoplay={{ delay: 5000, disableOnInteraction: false }}
          onSwiper={swiper => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <div className={style.testimonial__item}>
                <p className={style.testimonial__text}>
                  {item.testimonialText}
                </p>
                <p className={style.testimonial__name}>{item.patientName}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={style.testimonial__nav}>
          <button
            className={style.testimonial__nav_prev}
            onClick={handlePrev}
            aria-label="Previous slide"
          >
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 35 36"
            >
              <rect y=".81" width="35" height="35" rx="17.5" fill="#00A098" />
              <path
                d="m19.5 26.31-8-8 8-8"
                stroke="#fff"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            className={style.testimonial__nav_next}
            onClick={handleNext}
            aria-label="Next slide"
          >
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 35 36"
            >
              <rect y=".81" width="35" height="35" rx="17.5" fill="#00A098" />
              <path
                d="m19.5 26.31-8-8 8-8"
                stroke="#fff"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Testimonial

export const query = graphql`
  fragment Testimonial on DatoCmsTestimonial {
    id
    paddingTop
    paddingBottom
    items {
      testimonialText
      patientName
    }
    __typename
    model {
      apiKey
    }
  }
`
