import React from 'react'
import { Block, Testimonial } from '@components'

const DatoCmsTestimonial = ({
  paddingTop,
  paddingBottom,
  backgroundColour,
  ...props
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      backgroundColour={backgroundColour}
    >
      <Testimonial {...props} />
    </Block>
  )
}

export default DatoCmsTestimonial
