import React from 'react'
import { graphql, Link } from 'gatsby'
import style from './available-clinic.mod.scss'
import { Button, Container } from '@components'
import { useQuerySubscription } from 'react-datocms'

const AvailableClinic = ({
  link,
  theme,
  clinicsHeading,
  clinicsNotes,
  clinicsSubText,
  clinics,
  longText
}) => {
  // Add theme color mapping
  const themeColors = {
    careNow: { backgroundColor: '#00a098', path: '#fff', button: 'green' },
    dropInCare: { backgroundColor: '#f26862', path: '#fff', button: 'red' },
    medDirect: { backgroundColor: '#ffe512', path: '#000', button: 'yellow' },
    generalPractice: {
      backgroundColor: '#5cb7e7',
      path: '#000',
      button: 'blue'
    }
  }

  // Get colors based on theme
  const colors = theme
    ? themeColors[theme]
    : { backgroundColor: '#00a098', path: '#000', button: 'secondary' }

  const { data: liveData, status, error } = useQuerySubscription({
    enabled: true,
    query: `
    query MyQuery {
      allWaitTimes {
        id 
        estimatedWaitTime
        clinic {
          id
          name
        }
  }
    }`,
    token: 'c7f8c20e9e3b736f66e1e097fbb9a2',
    preview: false,
    environment: 'feature-wizard-form'
  })

  const waitTimes = liveData?.allWaitTimes ? liveData.allWaitTimes : false

  // Map through clinics and add waitTime property
  const clinicsWithWaitTimes = clinics.map(clinic => {
    const hasWaitTime =
      waitTimes && waitTimes.find(wt => clinic.id.includes(wt.clinic.id))
    return {
      ...clinic,
      waitTime: hasWaitTime ? hasWaitTime.estimatedWaitTime : false
    }
  })

  return (
    <Container maxWidth={'large'}>
      <div className={style['available-clinic']}>
        <div className={style['available-clinic__container']}>
          <div
            className={`${style['available-clinic__content']} ${style['available-clinic__content--clinics']}`}
            style={{ backgroundColor: `${colors.backgroundColor}40` }}
          >
            <h2>{clinicsHeading}</h2>
            <div className={style['available-clinic__clinics-list']}>
              {clinicsWithWaitTimes &&
                clinicsWithWaitTimes.map(clinic => (
                  <div
                    key={clinic.id}
                    className={style['available-clinic__clinic']}
                  >
                    <CheckIcon
                      className={style['available-clinic__clinic-icon']}
                      circleFill={colors.backgroundColor}
                      pathFill={colors.path}
                    />
                    <div className={style['available-clinic__clinic-details']}>
                      <h3
                        className={
                          style['available-clinic__clinic-details__name']
                        }
                      >
                        {clinic.name}
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: clinic.hours }}
                        className={
                          style['available-clinic__clinic-details__hours']
                        }
                      />
                      {clinic.waitTime && (
                        <div
                          className={`${style['available-clinic__wait-time']} theme-${theme}`}
                        >
                          <div
                            className={
                              style[`available-clinic__wait-time__text`]
                            }
                          >
                            Current Drop-inCare wait time:{' '}
                            <div
                              className={
                                style[
                                  `available-clinic__wait-time__text__value`
                                ]
                              }
                            >
                              {clinic.waitTime}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            <div className={style['available-clinic__notes']}>
              {clinicsSubText && (
                <p className={style['available-clinic__notes--subtext']}>
                  {clinicsSubText}
                </p>
              )}
              {clinicsNotes && (
                <p className={style['available-clinic__notes--notes']}>
                  {clinicsNotes}
                </p>
              )}
            </div>
          </div>

          <div className={style['available-clinic__content']}>
            <p>{longText}</p>
            <div className={style['available-clinic__button']}>
              <Button
                alignment="left"
                buttonStyle={colors.button}
                to={link?.link?.path || link?.url}
              >
                {link.displayText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default AvailableClinic

// Add the CheckIcon component at the bottom of the file
const CheckIcon = ({
  className,
  circleFill = '#00a098',
  pathFill = '#000'
}) => (
  <svg
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
  >
    <circle cx="11" cy="11" r="11" fill={circleFill} fillOpacity="1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.689 7.655c.277.24.303.657.058.93l-5.14 5.703a.67.67 0 0 1-.47.222.682.682 0 0 1-.49-.177l-3.351-3.07a.653.653 0 0 1-.03-.928.676.676 0 0 1 .944-.035l2.851 2.606 4.684-5.195a.67.67 0 0 1 .462-.22.682.682 0 0 1 .485.164h-.003Z"
      fill={pathFill}
    />
  </svg>
)

// The GraphQL fragment, unchanged:
export const query = graphql`
  fragment AvailableClinic on DatoCmsAvailableClinic {
    link {
      ...Link
    }
    clinicsHeading
    clinicsNotes
    clinicsSubText
    longText
    clinics {
      hours
      name
      id
    }
    paddingBottom
    paddingTop
    backgroundColour
    __typename
  }
`
