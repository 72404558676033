import React from 'react'
import { Block, FeatureItem } from '@components'

const DatoCmsFeatureItem = ({ paddingTop, paddingBottom, ...props }) => {
  return (
    <Block gutters paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <FeatureItem {...props} />
    </Block>
  )
}

export default DatoCmsFeatureItem
