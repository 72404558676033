import React from 'react'
import { graphql } from 'gatsby'
import style from './pill.mod.scss'
import { Container } from '@components'
const Pill = ({ text, colour, theme }) => {
  const themeScheme = theme?.length ? theme[0].scheme : false

  return (
    <Container maxWidth={'medium'}>
      <div
        className={`${style.pill} ${style[`pill--${colour}`]} ${
          style[`theme-${themeScheme}`]
        }`}
      >
        {text}
      </div>
    </Container>
  )
}

export const query = graphql`
  fragment Pill on DatoCmsPill {
    id
    text
    theme {
      id
      scheme
    }
    model {
      apiKey
    }
    paddingTop
    paddingBottom
    __typename
  }
`

export default Pill
