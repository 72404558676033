import React from 'react'
import style from './banner.mod.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Breadcrumbs, Button, Content } from '@components'

const Banner = ({
  image,
  heading = null,
  caption,
  contentBackgroundColor = 'grey',
  button,
  breadcrumbs
}) => {
  const modifiers = []
  contentBackgroundColor &&
    modifiers.push(
      style[`banner--content-background-color-${contentBackgroundColor}`]
    )

  return (
    <div className={[style.banner, ...modifiers].join(' ')}>
      <div className={style.banner__image}>
        <GatsbyImage
          className={style.banner__gatsby_image}
          image={image.gatsbyImageData}
          alt={image.alt || ''}
          loading={'eager'}
        />
      </div>
      <div className={style.banner__wrap}>
        {(heading || caption || button?.link?.path || button?.url) && (
          <div className={style.banner__content}>
            <div className={style.banner__content_body}>
              <div className={style.banner__upper}>
                {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
              </div>
              <div className={style.banner__lower}>
                <div className={style.banner__heading}>
                  {heading && <h1>{heading}</h1>}
                </div>

                {caption && (
                  <div className={style.banner__caption}>
                    <Content content={caption} />
                  </div>
                )}
                <div className={style.banner__button}>
                  {(button?.link?.path || button?.url) && (
                    <Button
                      alignment={'left'}
                      children={button.displayText}
                      color={'default'}
                      size={'large'}
                      length={'large'}
                      buttonStyle={'secondary'}
                      to={button?.link?.path || button.url}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Banner
