import React from 'react'
import style from './feature-grid.mod.scss'
import { graphql } from 'gatsby'
import { Grid } from '@components'

const FeatureGrid = ({ items, headingColour, itemsPerRow, iconOnTop }) => {
  const columns = itemsPerRow ? itemsPerRow : 3

  return (
    <div className={style['feature-grid']}>
      <Grid columns={columns} gridGap={'slim'}>
        {items.map(item => {
          return (
            <div
              className={`${style['feature-grid__item']} ${
                iconOnTop ? style['feature-grid__item--icon-top'] : ''
              }`}
            >
              <img src={item.icon.url} />
              <div>
                <div
                  className={
                    style[`feature-grid--heading-color-${headingColour}`]
                  }
                >
                  <h5>{item.title}</h5>
                </div>
                {item.description && <p>{item.description}</p>}
              </div>
            </div>
          )
        })}
      </Grid>
    </div>
  )
}

export default FeatureGrid

export const query = graphql`
  fragment FeatureGrid on DatoCmsFeatureGrid {
    items {
      title
      description
      icon {
        url
      }
    }
    paddingBottom
    paddingTop
    headingColour
    backgroundColour
    itemsPerRow
    iconOnTop
    __typename
  }
`
