import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import style from './image-with-content.mod.scss'
import { Button, Content, Image } from '@components'

const ImageWithContent = ({
  buttons,
  heading,
  content,
  image,
  largeListFonts,
  imagePosition,
  modifiers = [],
  backgroundColour = 'None'
}) => {
  imagePosition &&
    modifiers.push(
      `${style['image-with-content']}--${imagePosition
        .toLowerCase()
        .replace(/ /gi, '-')}`
    )
  backgroundColour &&
    modifiers.push(
      `${
        style['image-with-content']
      }--bg-colour-${backgroundColour.toLowerCase().replace(/ /gi, '-')}`
    )

  return (
    <div className={[style[`image-with-content`], ...modifiers].join(' ')}>
      <div className={style[`image-with-content__image`]}>
        <GatsbyImage image={image.gatsbyImageData} alt={image.alt || ''} />
      </div>
      <div className={style[`image-with-content__wrap`]}>
        <div className={style[`image-with-content__content`]}>
          <div className={style[`image-with-content__content_body`]}>
            {heading && (
              <div className={style[`image-with-content__heading`]}>
                <h2>{heading}</h2>
              </div>
            )}
            <Content content={content} largeListFont={true} />
            <div className={style[`image-with-content__buttons`]}>
              {buttons?.length > 0 &&
                buttons.map((button, index) => (
                  <Button
                    key={index}
                    to={button?.link?.path || button.url}
                    children={button?.displayText}
                    color={'default'}
                    buttonStyle={
                      backgroundColour === 'Grey' || backgroundColour === 'None'
                        ? 'primary'
                        : 'secondary'
                    }
                    length={'large'}
                    size={'large'}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageWithContent

export const query = graphql`
  fragment ImageWithContent on DatoCmsImageWithContent {
    buttons {
      ...Link
    }
    heading
    content
    image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { h: "600", fit: "crop", w: "800", q: 60 }
      )
      alt
    }
    id
    imagePosition
    model {
      apiKey
    }
    paddingBottom
    paddingTop
    largeListFonts
    backgroundColour
    __typename
  }
`
