import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import style from './mobile-services.mod.scss'
import MobileServicesIcon from './icons/mobile-services-icon'

const MobileServices = () => {
  const data = useStaticQuery(graphql`
    query MobileServicesQuery {
      allDatoCmsService {
        nodes {
          id
          title
          infographicText
          infographicSubtext
          theme {
            scheme
          }
          link {
            ...Link
          }
        }
      }
    }
  `)

  const services = data.allDatoCmsService.nodes

  return (
    <>
      <div className={style.mobile_services__icon}>
        <MobileServicesIcon />
      </div>
      <div className={style.mobile_services}>
        {services.map(service => (
          <Link key={service.id} to={service.link.link.path}>
            <div className={style.mobile_services__card}>
              <div
                className={[
                  style.mobile_services__card__title,
                  `theme-${service.theme[0].scheme}`
                ].join(' ')}
              >
                <h3>{service.title} </h3>
              </div>
              <div className={style.mobile_services__card__content}>
                <div className={style.mobile_services__card__text}>
                  {service.infographicText}
                </div>
                <div
                  className={style.mobile_services__subtext}
                  dangerouslySetInnerHTML={{
                    __html: service.infographicSubtext
                  }}
                />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  )
}

export default MobileServices
