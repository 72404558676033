import React from 'react'
import style from './module-area-with-aside.mod.scss'
import { graphql } from 'gatsby'
import BlockWrapper from '../pageBuilder/wrapper'

const ModuleAreaWithAside = ({ moduleArea, aside }) => {
  aside.forEach((block, i) => {
    if (block['__typename'] === 'DatoCmsCardGrid' || 'DatoCmsClinicCardGrid') {
      aside[i].columns = 1
      aside[i].isAside = true
    }
  })

  return (
    <div
      className={`${style[`module-area-with-aside`]} ${
        !aside?.length ? style[`module-area-with-aside--no-aside`] : ''
      }`}
    >
      <div className={style[`module-area-with-aside__module-area`]}>
        {moduleArea?.length > 0 && <BlockWrapper blocks={moduleArea} />}
      </div>
      <aside className={style[`module-area-with-aside__aside`]}>
        {aside?.length > 0 && <BlockWrapper blocks={aside} />}
      </aside>
    </div>
  )
}

export default ModuleAreaWithAside

export const query = graphql`
  fragment ModuleAreaWithAside on DatoCmsModuleAreaWithAside {
    id
    __typename
    model {
      apiKey
    }
    paddingBottom
    paddingTop
    moduleArea {
      ... on DatoCmsVideo {
        ...Video
      }
      ... on DatoCmsImage {
        ...Image
      }
      ... on DatoCmsImageWithContentInner {
        ...ImageWithContentInner
      }
      ... on DatoCmsDivider {
        ...Divider
      }
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsButton {
        ...Button
      }
      ... on DatoCmsBlockHeader {
        ...BlockHeader
      }
      ... on DatoCmsBookingButton {
        ...BookingButton
      }
      ... on DatoCmsTeamMemberCardGrid {
        ...TeamMemberCardGrid
      }
      ... on DatoCmsIframe {
        ...Iframe
      }
      ... on DatoCmsTable {
        ...Table
      }
      ... on DatoCmsAccordion {
        ...Accordion
      }
      ... on DatoCmsFeatureItem {
        ...FeatureItem
      }
    }
    aside {
      ... on DatoCmsClinicCardGrid {
        ...ClinicCardGrid
      }
      ... on DatoCmsCardGrid {
        ...CardGrid
      }
    }
  }
`
