import React, { useContext, useEffect } from 'react'
import style from './header.mod.scss'
import { Menu, Logo, Button } from '@components'
import { Link } from 'gatsby'
import BarsSvg from '@svgs/bars.svg'
import CloseSvg from '@svgs/close.svg'
import SearchSvg from '@svgs/search.svg'
import MyHealthSvg from '@svgs/my-health.svg'
import Arrow from '@svgs/arrow-right.svg'
import ThemeContext from '@context/themeContext'
import SearchContext from '@context/searchContext'

const Header = ({
  menuItems,
  wecareMenuItems,
  modifiers = [],
  isWecareNowPage
}) => {
  const { toggleSidebar, isSidebarOpen, isWeCareNow } = useContext(ThemeContext)
  const { setIsSearchModalOpen } = useContext(SearchContext)

  isWecareNowPage && modifiers.push([style['header--wecare-now']])

  return (
    <header className={[style.header, ...modifiers].join(' ')}>
      <div className={style.header__container}>
        <div className={style.header__wrapper}>
          <div className={style.header__upper}>
            <div className={style.header__logo}>
              <Logo colour={'primary'} type={'default'} />
            </div>
            <div className={style.header__menu}>
              <Menu items={menuItems} />
            </div>
            <div className={style.header__buttons}>
              <HeaderButtons />
            </div>
            <div className={style.header__controls}>
              <div className={style.header__search}>
                <button
                  onClick={() => setIsSearchModalOpen(true)}
                  aria-label={'Toggle Search'}
                >
                  <SearchSvg />
                </button>
              </div>
              <div className={style['header__manage-my-health']}>
                <a
                  href={'https://www.managemyhealth.co.nz/'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MyHealthSvg />
                </a>
              </div>
              {isSidebarOpen ? (
                <div className={style.header__close}>
                  <button onClick={toggleSidebar} aria-label={'Toggle Menu'}>
                    <CloseSvg />
                  </button>
                </div>
              ) : (
                <div className={style.header__bars}>
                  <button onClick={toggleSidebar} aria-label={'Toggle Menu'}>
                    <BarsSvg />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

const HeaderButtons = () => {
  return (
    <>
      <div className={style.header__buttons_mobile}>
        {/* <Button size={'large'} to={'/enrol/'}>
          {'Enrol'}
        </Button> */}
        <Button
          size={'large'}
          color={'solid'}
          buttonStyle={'primary'}
          to={'/clinics'}
        >
          {'Book an Appointment'}
        </Button>
      </div>
      <div className={style.header__buttons_desktop}>
        <Button
          size={'large'}
          icon={'person'}
          to={'https://www.managemyhealth.co.nz/'}
        >
          {'Patient Portal'}
        </Button>
        <Button
          size={'large'}
          color={'solid'}
          buttonStyle={'primary'}
          to={'/clinics'}
        >
          {'Book Appointment'}
        </Button>
      </div>
    </>
  )
}

const HeaderButtonsWeCareNow = () => {
  return (
    <>
      <div className={style.header__buttons_mobile}>
        <Button
          size={'large'}
          color={'inverse'}
          buttonStyle={'primary'}
          to={'/enrol/'}
        >
          {'Join WeCare'}
        </Button>
      </div>

      <div className={style.header__buttons_desktop}>
        {/* <Button
          size={'large'}
          color={'inverse'}
          buttonStyle={'primary'}
          to={'/questionnaire/'}
        >
          {'Get Started'}
        </Button> */}
        <Button
          size={'large'}
          color={'inverse'}
          buttonStyle={'primary'}
          to={'/enrol/'}
        >
          {'Join WeCare'}
        </Button>
      </div>
    </>
  )
}

export default Header
