import React from 'react'
import { Block, ClinicCard, Grid } from '@components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useQuerySubscription } from 'react-datocms'

const DatoCmsClinicCardGrid = ({
  paddingTop,
  paddingBottom,
  clinics,
  columns = 3,
  isAside
}) => {
  const { data: liveData, status, error } = useQuerySubscription({
    enabled: true,
    query: `
    query MyQuery {
      allWaitTimes {
        id 
        estimatedWaitTime
        clinic {
          id
          name
        }
  }
    }`,
    token: 'c7f8c20e9e3b736f66e1e097fbb9a2',
    preview: false,
    environment: 'feature-wizard-form'
  })

  const waitTimes = liveData?.allWaitTimes ? liveData.allWaitTimes : false

  // Map through clinics and add waitTime property
  const clinicsWithWaitTimes = clinics.map(clinic => {
    const hasWaitTime =
      waitTimes && waitTimes.find(wt => clinic.id.includes(wt.clinic.id))
    return {
      ...clinic,
      waitTime: hasWaitTime ? hasWaitTime.estimatedWaitTime : false
    }
  })

  return (
    <Block gutters paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Grid columns={columns} gridGap={'slim'}>
        {clinicsWithWaitTimes.map(clinic => {
          return <ClinicCard {...clinic} key={clinic.id} isAside={isAside} />
        })}
      </Grid>
    </Block>
  )
}

export default DatoCmsClinicCardGrid
