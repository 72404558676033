import React, { useState, useEffect } from 'react'
import { Infographic, Block } from '@components'
import MobileServices from '../components/mobile-services'
import MobileServicesIcon from '../components/icons/mobile-services-icon'

const DatoCmsInfographic = ({ paddingTop, paddingBottom, ...props }) => {
  const [isMobile, setIsMobile] = useState(false)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 1110)
    }

    checkScreenSize()
    window.addEventListener('resize', checkScreenSize)

    return () => window.removeEventListener('resize', checkScreenSize)
  }, [])

  if (!isClient) return null // Prevent hydration mismatch

  return (
    <Block gutters paddingTop={paddingTop} paddingBottom={paddingBottom}>
      {!isMobile ? <Infographic {...props} /> : <MobileServices />}
    </Block>
  )
}

export default DatoCmsInfographic
